import  React, {  useEffect ,useState } from "react"; 
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link , useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from "@mui/material/Typography";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import { updateLoggedInData } from "../../Assets/Redux/features/StoreData/userLoginDetails";
// -------- custom icons and images -----------

//import GliderLogo from "../../../Assets/qgliderlogo.png";
import noCueLogo from "../../../Assets/noCueLogo.png";
// import Doctor from "./Doctor.png";
import Doctor from "../../../Assets/LoginImage/noCue_img.jpg";
import LockIcon from '../../../Assets/icons/Lockicon.svg';
import UserIcon from '../../../Assets/icons/userIcon.svg';
import { IconButton, InputAdornment } from "@mui/material";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//-------- custom files ----------
import Controller from "../../../Controller/ApiController";
import { Alert } from "../../ReuseComponents/Alert/Alertmessage";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { updateCount } from "../../Assets/Redux/features/updateRequest/updateRequest";




export default function Loginpage() {

  const [showPassword, setShowPassword] = useState(false);
  const [RememberMe, setRememberMe] = useState(false);
  const [EnableSubmit,setEnableSubmit] = useState()
  const [loaderopen, setloaderOpen] = React.useState(false);
  const [ formData, setFormData ] = useState({
    user_name:"",
    password:""
  })
  const [formError,setFormError] = useState({
    user_name_error:false,
    password_error:false
  })
  const [pageNavigate , setPageNavigate ] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formDatas = useSelector( (state) => state.userLoginDetails.loggedInDetails)

  const updateCountValue = useSelector( (state) => state.updateRequest.update_count)

  const handleLoaderClose = () => {
    setloaderOpen(false);
  };
  const handleLoaderOpen = () => {
    setloaderOpen(true);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  let rememberMe = localStorage.getItem("Remember_me");
  useEffect(() => {
    
      if(rememberMe && ( rememberMe == true || rememberMe == "true")){
        RememberMeFunction()
      }
   
    
  }, []);

  useEffect(() => {

    if((formData.user_name != "" || formData.user_name.length != 0) && (formData.password != "" || formData.password.length != 0)){
      setEnableSubmit(false)
    }
    else{
      setEnableSubmit(true)
    }
    
  }, [formData]);

  const RememberMeFunction = () => {
    let user = localStorage.getItem("user_name");
    let switchON = localStorage.getItem("switchOn");
    let remember = localStorage.getItem("Remember_me")
    console.log(user,switchON)
    if(user && switchON) {
      setFormData({...formData,"user_name":user,"password":decryptData(switchON)})
      setRememberMe(remember)
    }
  }

  const onChangeValue = (event) => {
    setFormData({...formData,[event.target.name]:event.target.value})

    if(event.target.name == "user_name"){
      if(event.target.value === "" || event.target.value === null || event.target.value.length === 0){
        setFormError({"user_name_error":true})
      }
      else{
        setFormError({"user_name_error":false})
      }
     // setFormError({"user_name_error": !formData.user_name || formData.user_name === "" || formData.user_name === null || formData.user_name.length === 0 })
    }else{
      if(event.target.value === "" || event.target.value === null || event.target.value.length === 0){
        setFormError({"password_error":true})
      }
      else{
        setFormError({"password_error":false})
      }
     
      // setFormError({"password_error":!formData.password || formData.password === "" || formData.password === null || formData.password.length === 0})
    }
  };

  const onChangeCheckbox = (event) => {
    console.log(event.target.checked)
    setRememberMe(event.target.checked);
  };


  const secretPass = "XkhZG4fW2t2W";
  const encryptData = (EncryptData) => {
    const data = CryptoJS.AES.encrypt(JSON.stringify(EncryptData),secretPass).toString();
    return data
  };

  const decryptData = (decryptData) => {
    const bytes = CryptoJS.AES.decrypt(decryptData, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  };
  const validation = (FormData) => {
    setFormError({
      "user_name_error": !FormData.user_name || FormData.user_name === "" || FormData.user_name === null || FormData.user_name.length === 0 ,
      "password_error":!FormData.password || FormData.password === "" || FormData.password === null || FormData.password.length === 0
    })
    let isValid ;
    for(let i in formError){
      if(formError[i] ===  false){
        isValid = true;
      }
      else{
        isValid = false;
        break;
      }
    }
    return isValid
  }

  

  const handleSubmit = async (event) => {
    event.preventDefault();
    handleLoaderOpen();
    try{
      let isValidData = validation(formData);
      if(isValidData){
        //------------- Post api-----------------
       let res = await Controller.ApiController("POST","/doctor/login","",formData)
       handleLoaderClose();
       console.log(res,"loginData.uid")
        if(res){
          if(res.type === "success"){
            localStorage.setItem('access_permissions', res.data.access_permissions)
            const loggedInData = {
              user_name: formData.user_name,
              password: formData.password,
              full_name: res.data.full_name,
              doctor_name: res.data.doctor_name,
              user_id: res.data.user_uid,
              doctor_uid: res.data.doctor ? res.data.doctor.doctor_uid : res.data.doctor_uid,
              hospital_or_clinic_name: res.data.hospital_or_clinic_name,
              user_type: res.data.user_type,
            };
            setPageNavigate(res.data.access_permissions)        
            if(RememberMe){
              localStorage.setItem('user_name', formData.user_name);
              localStorage.setItem('switchOn', encryptData(formData.password));
              localStorage.setItem('Remember_me', true);
              dispatch(updateLoggedInData(formData.user_name))
              dispatch(updateLoggedInData(formData.password))
              if(res.data){
              localStorage.setItem('full_name',res.data.full_name );
              localStorage.setItem('DoctorName',res.data.doctor_name );
              dispatch(updateLoggedInData(res.data.full_name));
              dispatch(updateLoggedInData(loggedInData))
              if(res.data.doctor){
                localStorage.setItem('user_uid',res.data.user_uid );
                localStorage.setItem('DoctorUid', res.data.doctor.doctor_uid);
                dispatch(updateLoggedInData(res.data.user_uid));
                dispatch(updateLoggedInData(res.data.doctor.doctor_uid))
              }
              else{
              localStorage.setItem('DoctorUid', res.data.doctor_uid);
              dispatch(updateLoggedInData(res.data.doctor_uid));
              }
              dispatch(updateLoggedInData(res.data.user_name))
              dispatch(updateLoggedInData(res.data.hospital_or_clinic_name))
              dispatch(updateLoggedInData(res.data.user_type))
              localStorage.setItem('user_name', res.data.user_name);
              localStorage.setItem('hospital_name', res.data.hospital_or_clinic_name)
              localStorage.setItem('user_type', res.data.user_type)
              }

            }
            else{
              localStorage.removeItem('user_name')
              localStorage.removeItem('switchOn')
              localStorage.removeItem('Remember_me')
              localStorage.removeItem('booked_count')
              if(res.data){
                localStorage.setItem('full_name',res.data.full_name );
                localStorage.setItem('DoctorName',res.data.doctor_name );
                dispatch(updateLoggedInData(res.data.full_name))
                dispatch(updateLoggedInData(res.data.doctor_name))
                if(res.data.doctor){
                  localStorage.setItem('DoctorUid', res.data.doctor.doctor_uid);
                  localStorage.setItem('user_uid', res.data.user_uid);
                  dispatch(updateLoggedInData(res.data.doctor.doctor_uid))
                  dispatch(updateLoggedInData(res.data.user_uid ))
                }
                else{
                localStorage.setItem('DoctorUid', res.data.doctor_uid);
                dispatch(updateLoggedInData(res.data.doctor_uid));
                
                }
                localStorage.setItem('user_name', res.data.user_name);
                localStorage.setItem('hospital_name', res.data.hospital_or_clinic_name)
                localStorage.setItem('user_type', res.data.user_type);
                dispatch(updateLoggedInData( res.data.user_name))
                dispatch(updateLoggedInData(res.data.hospital_or_clinic_name))
                dispatch(updateLoggedInData(res.data.user_type ))
                }
            }
            let access = res.data.access_permissions;           
            if(access){
              let pageNoAccess = access.includes("DASHBOARD") || access.includes("MANAGE_APPOINTMENTS") || access.includes("MANAGE_USERS") || access.includes("SETTINGS");
              window.location.href = (access.includes("DASHBOARD") ? "/dashboard" : access.includes("MANAGE_APPOINTMENTS") ? "/manageappointments" : access.includes("MANAGE_USERS") ?  "/manageusers" : access.includes("SETTINGS") ? "/settings" : !pageNoAccess  ? "/noaccesspage" : "/dashboard")
            }else{
              window.location.href = ("/dashboard")
            }            
            dispatch(updateCount({ update_count: updateCountValue + 1 }));
          }
          else{
            if(res.error.type === "error"){
              console.log(res.error,"ddddddd")
              if(res.error?.data && !res.error.data.is_mobile_verified ){
                sessionStorage.setItem("otp", res.error.data.otp)
                localStorage.setItem("DoctorUid", res.error.data.doctor_uid);
                localStorage.setItem("user_name", res.error.data?.user_name || "");
                console.log("navigate");
                navigate(`/signupotp`, { state: { user_name: formData.user_name } });
              } else {
                Alert("error", res.error.message);
              }
            }
           
          }
        }
        else{
          console.log("something went wrong")
        }
      }
    }
    catch(error){
      console.log(error)
    }

  };

  return (
    <Grid container component="main" sx={{ height: "100vh", padding: "20px" }}>
      <CssBaseline />
      <Grid item xs={12} sm={8} md={6} elevation={6} sx={{padding: {xl:"0px 120px", xs:"0px 0px", lg:"0px 100px", md:"0px 50px"} }}>
        <Box sx={{ display: "flex", flexDirection: "column", }} >
          <Box>
            <img src={noCueLogo} alt={noCueLogo + ".logo"} width={"155px"}  />
          </Box>
          <Box sx={{marginTop:"50px"}} >
            <h2 style={{fontWeight:"600",paddingBottom:"25px"}}>
              Sign in
            </h2>
            <Typography sx={{fontSize:"16px", fontWeight:"400"}}>
              If you don’t have an account registered
            </Typography>
            <Box display={"flex"} >
              <Typography> 
                   You can 
              </Typography>
              <Link to="/signup"  style={{textDecoration:"none", color:"#0C21C1", fontSize:"16px", fontWeight:"600", marginLeft:"4px"}}>
              Register here !
              </Link>
            </Box>
          </Box>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 4,maxHeight:{xs:"auto", sm:"50vh"},overflowY:{xs:"", sm:"auto"}, overflowX:{xs:"", sm:"hidden"}, paddingRight:"10px" }} autoComplete="off  ">
            <TextField required fullWidth  id="Username" label="Username" name="user_name" value={formData.user_name} placeholder="Enter your username / mobile number"
            variant="standard" autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                 <img src={UserIcon} style={{color:"#000000"}}/>
                </InputAdornment>
              ),
            }}
            sx={{paddingBottom:"4%", "label.Mui-focused": { color: "#999999" }, "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
            "& label": {marginTop:"1%", fontSize:"18px", fontWeight:"500", color:"#999999"},
            }}
            onChange={onChangeValue}
            />
            <TextField required  fullWidth id="Password" label="Password" name="password" value={formData.password} placeholder="Enter your password"  variant="standard" 
            type={showPassword ? "text" : "password"} autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={LockIcon} style={{color:"#000000"}}/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                  
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{paddingBottom:"4%", "label.Mui-focused": { color: "#999999" }, "& .MuiInput-underline:after": { borderBottomColor: "#999999" },
              '& input::-ms-reveal, & input::-ms-clear': {
                display: 'none',},
                "& label": {marginTop:"-1%", fontSize:"18px", fontWeight:"500", color:"#999999"} 
            }}
            onChange={onChangeValue}
            />

            <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
              <FormControlLabel checked={RememberMe} onChange={onChangeCheckbox} control={<Checkbox value={RememberMe} color="primary" />} label="Remember me" />                
              <Link to="/forgetpassword" variant="body2" style={{textDecoration:"none", color:"#000000DE"}}>  <Typography>Forgot password?</Typography> </Link>
            </Box>
            {/* <Link to="/dashboard"> */}
            <div style={{display:"flex", justifyContent:"center"}}>
              <Button type="submit" disabled={EnableSubmit}  fullWidth sx={{ mt: 3, mb: 2, backgroundColor:EnableSubmit?"#dddddd" :"#41BA8F" , color:"#FFFFFF", border:"1px solid #FFF", borderRadius:"50px", height:"40px", width:"200px", 
              '&:hover': { background:'#41BA8F' }
              }} >

                Login
              </Button></div>
            {/* </Link> */}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={false} sm={4} md={6}  sx={{ backgroundImage: "url(" + Doctor + ")", backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      />
       <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loaderopen}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
    </Grid>
    
  );
}
